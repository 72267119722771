import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";

import { useAuth } from "../hooks/use-auth.js";
import { useTranslation } from "../hooks/use-translation.js";

import Input from "../components/input.jsx";
import Button from "../components/button.jsx";
import Header from "../components/header.jsx";

export default function Page() {
  const [user] = useAuth();
  const { tcommon, tpage } = useTranslation({
    page: "home",
  });

  return (
    <>
      <Header subheader={tpage("subheader")} />

      <Input defaultValue={user.email} disabled />

      <Link to="/details">
        <Button>
          <span>{tcommon("continue")}</span>
          <ArrowRight size={14} />
        </Button>
      </Link>
    </>
  );
}
