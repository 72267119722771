import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import pick from "just-pick";

import { useAuth } from "../hooks/use-auth.js";
import { useTranslation } from "../hooks/use-translation.js";
import { useOnboarding } from "../hooks/use-onboarding.js";

import { Api } from "../services/api.js";

import Input from "../components/input.jsx";
import Button from "../components/button.jsx";
import Header from "../components/header.jsx";
import Select from "../components/select.jsx";

import timezones from 'timezones-list';

export default function Page() {
  const [user] = useAuth();
  const [onboarding, { set }] = useOnboarding();
  const { tcommon, tpage } = useTranslation({
    page: "details",
  });

  const navigate = useNavigate();
  const [working, setWorking] = useState(false);
  const [fields, setFields] = useState({
      first_name: onboarding["details.first_name"] ?? "",
      last_name: onboarding["details.last_name"] ?? "",
      phone_number: onboarding["details.phone_number"] ?? "",
      address_line_1: onboarding["address.address_line_1"] ?? "",
      postcode: onboarding["address.postcode"] ?? "",
      country: onboarding["address.country"] ?? "",
      timezone: onboarding["address.timezone"] ?? "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
  });

  const submit = async (e) => {
    e.preventDefault();

    setWorking(true);

    const details = pick(fields, [
      'first_name', 'last_name', 'phone_number'
    ])

    const address = pick(fields, [
      'address_line_1', 'postcode', 'country', 'timezone'
    ])

    const api = new Api()
    const res = await Promise.all([
      api.updateDetails(details),
      api.updateAddress(address)
    ])

    if (!res.every(r => r.ok)) {
      setWorking(false);
      return;
    }

    set({ details, address });
    navigate("/network");
  };

  useEffect(() => {
    setFields({
      first_name: onboarding["details.first_name"] ?? "",
      last_name: onboarding["details.last_name"] ?? "",
      phone_number: onboarding["details.phone_number"] ?? "",
      address_line_1: onboarding["address.address_line_1"] ?? "",
      postcode: onboarding["address.postcode"] ?? "",
      country: onboarding["address.country"] ?? "",
      timezone: onboarding["address.timezone"] ?? "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
    });
  }, [onboarding]);

  return (
    <>
      <Header subheader={tpage("subheader")} />

      <form className="space-y-4" onSubmit={submit}>
        <div className="flex flex-wrap gap-4">
          <Input
            label={tpage("email-address")}
            defaultValue={user.email}
            disabled
          />
          <Input
            label={tpage("phone-number")}
            value={fields.phone_number}
            onChange={(e) =>
              setFields({ ...fields, phone_number: e.target.value })
            }
            required
          />
        </div>
        <div className="flex flex-wrap gap-4">
          <Input
            label={tpage("first-name")}
            value={fields.first_name}
            onChange={(e) =>
              setFields({ ...fields, first_name: e.target.value })
            }
            required
          />
          <Input
            label={tpage("last-name")}
            value={fields.last_name}
            onChange={(e) =>
              setFields({ ...fields, last_name: e.target.value })
            }
            required
          />
        </div>
        <div className="flex flex-wrap gap-4">
          <Input
            label={tpage("address-line-1")}
            value={fields.address_line_1}
            onChange={(e) =>
              setFields({ ...fields, address_line_1: e.target.value })
            }
            required
          />
          <Input
            label={tpage("post-code")}
            value={fields.postcode}
            onChange={(e) => setFields({ ...fields, postcode: e.target.value })}
            required
          />
        </div>
        <div className="flex flex-wrap gap-4">
          <Input
            label={tpage("country")}
            value={fields.country}
            onChange={(e) => setFields({ ...fields, country: e.target.value })}
            required
          />
          <Select
            label={tpage("timezone")} 
            options = {timezones}
            keyProp = "name"
            valueProp = "name"
            value={fields.timezone}
            onChange={(e) =>
              setFields({ ...fields, timezone: e.target.value })
            }
          />
        </div>

        <Button type="submit" working={working}>
          <span>{tcommon("continue")}</span>
          <ArrowRight size={14} />
        </Button>
      </form>
    </>
  );
}
