import { Routes, Route } from 'react-router-dom'

import { useAuth } from './hooks/use-auth.js'
import { useOnboarding } from './hooks/use-onboarding.js'

import Home from './pages/home.jsx'
import Details from './pages/details.jsx'
import Network from './pages/network.jsx'
import Terms from './pages/terms-and-conditions.jsx'
import Completed from './pages/completed.jsx'

import Navbar from './navbar.jsx'
import Container from './container.jsx'
import Footer from './footer.jsx'
import VerifyAuth from './verify-auth.jsx'
import PrefillOnboarding from './prefill-onboarding.jsx'

export default function App () {
  const [user] = useAuth()
  const [onboarding] = useOnboarding()

  return (
    <>
      <Navbar />

      <Container>
        {!user && <VerifyAuth />}
        {user && !onboarding && <PrefillOnboarding />}
        {user && onboarding && (
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/details' element={<Details />} />
            <Route path='/network' element={<Network />} />
            <Route path='/terms-and-conditions' element={<Terms />} />
            <Route path='/completed' element={<Completed />} />
          </Routes>
        )}
      </Container>

      <Footer />
    </>
  )
}
