import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Check } from "lucide-react";
import pick from "just-pick";

import { useTranslation } from "../hooks/use-translation.js";
import { useOnboarding } from "../hooks/use-onboarding.js";

import { Api } from "../services/api.js";

import Checkbox from "../components/checkbox.jsx";
import Button from "../components/button.jsx";
import Header from "../components/header.jsx";

export default function Page() {
  const [, { raw: onboarding, set }] = useOnboarding();
  const { tcommon, tpage } = useTranslation({
    page: "terms-and-conditions",
  });

  const navigate = useNavigate();
  const [working, setWorking] = useState(false);
  const [fields, setFields] = useState({});

  const submit = async (e) => {
    e.preventDefault();

    setWorking(true);

    const documents = Object.entries(fields).reduce((acc, [type, value]) => {
      const document = onboarding.documents.find(doc => doc.type === type)

      return [ ...acc, { ...document, confirmed: !!value }]
    }, [])

    const res = await new Api().updateDocuments({
      documents: documents.map(doc => pick(doc, ['id', 'confirmed']))
    })

    if (!res.ok) {
      setWorking(false)
      return
    }

    set({ documents })

    navigate("/completed");
  };

  useEffect(() => {
    const { documents = [] } = onboarding

    setFields(documents.reduce((acc, doc) => (
      { ...acc, [doc.type]: doc.confirmed }
    ), {}))
  }, [onboarding])

  return (
    <>
      <Header subheader={tpage("subheader")} />

      <form className="space-y-4" onSubmit={submit}>
        {Object.entries(fields).map(([type, value]) => {
          const document = onboarding.documents.find(doc => doc.type === type)

          return (
            <Checkbox
              key={type}
              label={document.title}
              hint={document.description}
              uri={document.uri}
              checked={value}
              onChange={(e) =>
                setFields({ ...fields, [type]: e.target.checked })
              }
              required
            />
          )
        })}

        <div className="flex gap-2">
          <Link to="/network">
            <Button theme="secondary">
              <span>{tcommon("back")}</span>
            </Button>
          </Link>
          <Button type="submit" working={working}>
            <span>{tcommon("continue")}</span>
            <Check size={14} />
          </Button>
        </div>
      </form>
    </>
  );
}
