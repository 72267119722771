import Wrapper from './components/wrapper.jsx'

export default function Footer () {
  return (
    <footer className='py-8'>
      <Wrapper>
        <p className='text-xs text-primary-muted'>
          GivEnergy &copy; {new Date().getUTCFullYear()}
        </p>
      </Wrapper>
    </footer>
  )
}
