import { useEffect, useState, useCallback, useRef } from "react";
import { Loader, ShieldCheck, ShieldAlert } from "lucide-react";

import { jwtDecode } from "./utils/jwt-decode.js";
import { useAuth } from "./hooks/use-auth.js";
import { useTranslation } from "./hooks/use-translation.js";

import { Api } from "./services/api.js";

import EmptyState from "./components/empty-state.jsx";

export default function VerifyAuth() {
  const [, { login, logout }] = useAuth();
  const { tcomponent } = useTranslation({
    component: "verify-auth",
  });

  const [state, setState] = useState(null);
  const timeout = useRef(null);

  const success = useCallback(
    (token, user) => {
      setState(1);

      timeout.current = setTimeout(() => {
        login(token, user);
      }, 1000);
    },
    [login, setState]
  );

  // Handle authenticating fresh onboarding sessions
  useEffect(() => {
    const fn = async () => {
      try {
        const persisted = window.localStorage.getItem("token");

        if (persisted || state !== null) {
          return;
        }

        console.debug("Starting new onboarding session...");

        const query = new URLSearchParams(window.location.search);
        const token = query.get("token");

        // Determine if token was presented in the query string
        if (!token) {
          throw Error("No token found");
        }

        const regexp = /(^[\w-]*\.[\w-]*\.[\w-]*$)/;
        const matches = token.match(regexp);

        // Determine if token is "JWT-like"
        if (!matches) {
          throw Error("Token malformed");
        }

        window.localStorage.setItem("token", token);

        const res = await new Api().verify();

        if (!res.ok) {
          throw Error("Invalid JWT token");
        }

        const decoded = jwtDecode(matches[1]);

        success(token, decoded);
      } catch (err) {
        console.error(err);

        logout();
        setState(0);
      }
    };

    fn();
  }, [logout, state, success]);

  // Handle authenticating persisted onboarding sessions
  useEffect(() => {
    const fn = async () => {
      try {
        const token = window.localStorage.getItem("token");

        if (!token || state !== null) {
          return;
        }

        console.debug("Continuing existing onboarding session...");

        const res = await new Api().verify();

        if (!res.ok) {
          throw Error("Invalid token");
        }

        const decoded = jwtDecode(token);

        success(token, decoded);
      } catch (err) {
        console.error(err);

        logout();
        setState(0);
      }
    };

    fn();
  }, [logout, state, success]);

  // Handle clearing success timeout
  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <EmptyState>
      {state === null && (
        <>
          <Loader className="animate-spin" />
          <p>{tcomponent("verifying")}</p>
        </>
      )}
      {state === 1 && (
        <>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-full">
            <ShieldCheck />
          </div>
          <p className="font-medium text-primary-header">
            {tcomponent("verified")}
          </p>
          <p className="text-center">{tcomponent("redirect")}</p>
        </>
      )}
      {state === 0 && (
        <>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-red-500 rounded-full">
            <ShieldAlert />
          </div>
          <p className="font-medium text-primary-header">
            {tcomponent("unable-to-verify")}
          </p>
          <p className="text-center">{tcomponent("contact-installer")}</p>
        </>
      )}
    </EmptyState>
  );
}
