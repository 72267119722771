const { REACT_APP_CUSTOMER_ONBOARDING_API_URL } = process.env

export class Api {
  get token () {
    return window.localStorage.getItem('token')
  }

  get user () {
    return JSON.parse(window.localStorage.getItem('user'))
  }

  url (endpoint) {
    const _endpoint = endpoint.slice(+endpoint.startsWith('/'))
    let _base = REACT_APP_CUSTOMER_ONBOARDING_API_URL

    if (!_base.endsWith('/')) {
      _base += '/'
    }

    return new URL(_endpoint, _base)
  }

  verify () {
    return this.get()
  }

  get () {
    return this.handler(this.url('/onboarding/intent'), { method: 'GET' })
  }

  getDocuments () {
    return this.handler(this.url('/onboarding/intent/documents'), { method: 'GET' })
  }

  updateDetails (data) {
    return this.handler(
      this.url('/onboarding/intent/details'),
      { method: 'PATCH', body: JSON.stringify(data) }
    )
  }

  updateAddress (data) {
    return this.handler(
      this.url('/onboarding/intent/address'),
      { method: 'PATCH', body: JSON.stringify(data) }
    )
  }

  updateAddressNetwork (data) {
    return this.handler(
      this.url('/onboarding/intent/address/network'),
      { method: 'PATCH', body: JSON.stringify(data) }
    )
  }

  updateDocuments (data) {
    return this.handler(
      this.url('/onboarding/intent/documents'),
      { method: 'PATCH', body: JSON.stringify(data) }
    )
  }

  handler (url, options) {
    return fetch(url, {
      ...options,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }
}
