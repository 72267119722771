import { forwardRef } from "react";

import { cn } from "../utils/class-names.js";

const Select = forwardRef(function Select(
  { label, className, options, keyProp, valueProp, value, ...rest },
  ref
) {
  return (
    <label className={cn("block w-64 space-y-1", className)}>
      {label && (
        <span className="block text-sm font-medium capitalize">{label}</span>
      )}
      <select
        {...rest}
        value={value}
        className="w-full px-3 py-2 text-sm border-transparent rounded-md shadow ring-1 ring-primary/50 text-primary placeholder:text-primary-muted disabled:bg-primary-100 disabled:cursor-not-allowed"
        ref={ref}
      >
        {Array.isArray(options) &&
          options.map((timezone) => {
            return (
              <option key={timezone[keyProp]} value={timezone[valueProp]}>
                {timezone[keyProp]}
              </option>
            );
          })}
      </select>
    </label>
  );
});

export default Select;
