import { Home, LogOut } from 'lucide-react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from './hooks/use-auth.js'

import Wrapper from './components/wrapper.jsx'

export default function Navbar () {
  const [user, { logout }] = useAuth()
  const navigate = useNavigate()

  const click = () => {
    logout()
    navigate('/')
  }

  return (
    <nav className='py-4 bg-black text-white'>
      <Wrapper>
        <div className='flex items-center gap-4 text-xs'>
          <Link className='py-0.5' to='/'>
            <Home size={12} />
          </Link>
          {user && (
            <>
              <p className='ml-auto'>
                {user.email}
              </p>
              <button onClick={click}>
                <LogOut size={12} />
              </button>
            </>
          )}
        </div>
      </Wrapper>
    </nav>
  )
}
