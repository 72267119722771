import Wrapper from './components/wrapper.jsx'

export default function Container ({ children }) {
  return (
    <Wrapper className='mt-8 flex items-center'>
      <div className='w-full rounded-lg shadow p-8 space-y-4 bg-white'>
        {children}
      </div>
    </Wrapper>
  )
}
