import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import pick from "just-pick";

import { useTranslation } from "../hooks/use-translation.js";
import { useOnboarding } from "../hooks/use-onboarding.js";

import { Api } from "../services/api.js";

import Input from "../components/input.jsx";
import PasswordInput from "../components/password-input.jsx";
import Button from "../components/button.jsx";
import Header from "../components/header.jsx";

export default function Page() {
  const [onboarding, { set }] = useOnboarding();
  const { tpage, tcommon } = useTranslation({
    page: "network",
  });

  const navigate = useNavigate();
  const [working, setWorking] = useState(false);
  const [fields, setFields] = useState({
    ssid: onboarding["network.ssid"] ?? "",
    password: onboarding["network.password"] ?? "",
    password_confirm: "",
  });

  const confirmation = useRef(null);

  const submit = async (e) => {
    e.preventDefault();

    setWorking(true);

    const network = pick(fields, ['ssid', 'password']);
    const res = await new Api().updateAddressNetwork(network);

    if (!res.ok) {
      setWorking(false);
      return;
    }

    set({ network });
    navigate("/terms-and-conditions");
  };

  useEffect(() => {
    setFields({
      ssid: onboarding["network.ssid"] ?? "",
      password: onboarding["network.password"] ?? "",
      password_confirm: "",
    });
  }, [onboarding]);

  useEffect(() => {
    if (document.activeElement === confirmation.current) {
      confirmation.current.setCustomValidity(
        fields.password !== fields.password_confirm
          ? tpage("passwords-do-not-match")
          : ""
      );

      confirmation.current.reportValidity();
    }
  }, [fields.password, fields.password_confirm, tpage]);

  return (
    <>
      <Header subheader={tpage("subheader")} />

      <form className="space-y-4" onSubmit={submit}>
        <Input
          label={tpage("network-ssid")}
          value={fields.ssid}
          onChange={(e) => setFields({ ...fields, ssid: e.target.value })}
          required
        />
        <PasswordInput
          label={tpage("network-password")}
          type="password"
          value={fields.password}
          onChange={(e) => setFields({ ...fields, password: e.target.value })}
          required
        />
        <Input
          ref={confirmation}
          label={tpage("confirm-network-password")}
          type="password"
          value={fields.password_confirm}
          onChange={(e) =>
            setFields({ ...fields, password_confirm: e.target.value })
          }
          required
        />

        <div className="flex gap-2">
          <Link to="/details">
            <Button theme="secondary">{tcommon("back")}</Button>
          </Link>
          <Button type="submit" working={working}>
            <span>{tcommon("continue")}</span>
            <ArrowRight size={14} />
          </Button>
        </div>
      </form>
    </>
  );
}
