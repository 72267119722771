import { forwardRef } from 'react'

import { cn } from '../utils/class-names.js'

const Input = forwardRef(
  function Input ({ label, hint, uri, className, ...rest }, ref) {
    return (
      <label className={cn('block', className)}>
        <div className='flex items-start gap-4'>
          <input
            {...rest}
            ref={ref}
            type='checkbox'
            className='w-4 h-4 mt-1 rounded-md ring-1 ring-primary/50 border-transparent shadow'
          />
          <div className='space-y-1 text-sm'>
            <span className='block font-medium capitalize'>
              {label}
            </span>
            {uri && (
                <a href={uri} target="_blank" rel="noopener noreferrer">Link to document</a>
            )}
            {hint && (
              <span className='block text-primary-muted'>
                {hint}
              </span>
            )}
          </div>
        </div>
      </label>
    )
  }
)

export default Input
