import { useEffect, useContext, useCallback, useMemo } from 'react'
import { unflatten } from 'flat'

import { OnboardingContext } from '../providers/onboarding-provider.jsx'

import { Api } from '../services/api.js'

export function useOnboarding () {
  const [value, set] = useContext(OnboardingContext)

  const raw = useMemo(() => unflatten(value), [value])

  const refresh = useCallback(async () => {
    const res = await (new Api()).get()

    if (!res.ok) {
      return false
    }

    const { payload } = await res.json()

    set(payload)

    return true
  }, [set])

  useEffect(() => {
    console.debug('Onboarding changed %O', value)
  }, [value])

  return [value, { raw, set, refresh }]
}
