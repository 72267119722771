import { useEffect, useState, useCallback, useRef } from "react";
import { Loader, FileCheck2, FileX2 } from "lucide-react";

import { useAuth } from "./hooks/use-auth.js";
import { useOnboarding } from "./hooks/use-onboarding.js";
import { useTranslation } from "./hooks/use-translation.js";

import { Api } from "./services/api.js";

import EmptyState from "./components/empty-state.jsx";

export default function PrefillOnboarding() {
  const [user] = useAuth();
  const { tcomponent } = useTranslation({
    component: "prefill-onboarding",
  });

  const [, { set }] = useOnboarding();
  const [state, setState] = useState(null);
  const timeout = useRef(null);

  const success = useCallback(
    (payload) => {
      setState(1);

      timeout.current = setTimeout(() => {
        set(payload);
      }, 1000);
    },
    [set]
  );

  useEffect(() => {
    const fn = async () => {
      if (!user || state !== null) {
        return;
      }

      console.debug("Fetching onboarding information...");

      const api = new Api()
      const res = await Promise.all([
        api.get(),
        api.getDocuments()
      ]);

      if (!res.every(r => r.ok)) {
        setState(0);
        return;
      }

      const intent = await res[0].json();
      const documents = await res[1].json();

      success({
        ...intent,
        documents: Object.entries(documents)
          .reduce((acc, [, docs]) => [ ...acc, ...docs ], [])
      });
    };

    fn();
  }, [user, state, success]);

  useEffect(() => {
    return () => {
      clearTimeout(timeout.current);
    };
  }, []);

  return (
    <EmptyState>
      {state === null && (
        <>
          <Loader className="animate-spin" />
          <p>{tcomponent("loading")}</p>
        </>
      )}
      {state === 1 && (
        <>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-full">
            <FileCheck2 />
          </div>
          <p className="font-medium text-primary-header">
            {tcomponent("loaded")}
          </p>
          <p>{tcomponent("redirect")}</p>
        </>
      )}
      {state === 0 && (
        <>
          <div className="flex items-center justify-center w-12 h-12 text-white bg-red-500 rounded-full">
            <FileX2 />
          </div>
          <p className="font-medium text-primary-header">
            {tcomponent("unable-to-load")}
          </p>
          <p>{tcomponent("contact-installer")}</p>
        </>
      )}
    </EmptyState>
  );
}
