import { forwardRef } from 'react'
import { Loader } from 'lucide-react'

import { cn } from '../utils/class-names.js'

const Button = forwardRef(
  function Button ({ children, theme, working, className, type = 'button', disabled, ...rest }, ref) {
    return (
      <button
        {...rest}
        ref={ref}
        type={type}
        disabled={disabled || working}
        className={cn(
          'relative',
          'px-3 py-2 rounded-md bg-secondary-500 text-white text-sm',
          'disabled:cursor-not-allowed',
          theme === 'secondary' &&
          'bg-transparent ring-1 ring-primary/50 shadow-sm text-primary',
          working && 'opacity-75',
          className
        )}
      >
        <span
          className={cn(
            'flex items-center gap-2',
            working && 'opacity-0 pointer-events-none'
          )}
        >
          {children}
        </span>
        {working && (
          <span className='absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2'>
            <Loader className='animate-spin' size={14} />
          </span>
        )}
      </button>
    )
  }
)

export default Button
