import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { InjectTheme, ThemeProvider } from '@givenergy/tailwind-theming'

import reportWebVitals from './reportWebVitals'

import AuthProvider from './providers/auth-provider.jsx'
import OnboardingProvider from './providers/onboarding-provider.jsx'

import App from './app.jsx'
import i18n from './i18n.js'

import './index.css'

document.body.classList.add('text-primary')

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <BrowserRouter>
          <AuthProvider>
            <OnboardingProvider>
              <App />
              <InjectTheme />
            </OnboardingProvider>
          </AuthProvider>
        </BrowserRouter>
      </ThemeProvider>
    </I18nextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
