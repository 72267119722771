import { createContext, useReducer } from 'react'
import { flatten } from 'flat'

function reducer (oldState, newState) {
  return {
    ...oldState,
    ...flatten(newState)
  }
}

export const OnboardingContext = createContext(null)

export default function OnboardingProvider ({ children }) {
  const [value, set] = useReducer(reducer, null)

  return (
    <OnboardingContext.Provider value={[value, set]}>
      {children}
    </OnboardingContext.Provider>
  )
}
