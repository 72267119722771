import { createContext, useReducer } from 'react'

function reducer (_, newState) {
  return newState
}

export const AuthContext = createContext(null)

export default function AuthProvider ({ children }) {
  const [state, set] = useReducer(reducer, null)

  return (
    <AuthContext.Provider value={[state, set]}>
      {children}
    </AuthContext.Provider>
  )
}
