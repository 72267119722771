import { useTranslation as usei18n } from "react-i18next";

export function useTranslation({ ns, options, page, component }) {
  const { t, ...rest } = usei18n(ns, { ...options, keyPrefix: undefined });

  return {
    translate: t,
    tpage: (key) => t(`page.${page}.${key}`),
    tcommon: (key) => t(`word.${key}`),
    tcomponent: (key) => t(`component.${component}.${key}`),
    ...rest,
  };
}
