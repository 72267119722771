import { useTranslation } from "../hooks/use-translation.js"

export default function Header({ subheader }) {
  const { tcomponent } = useTranslation({
    component:"header"
  })

  return (
    <hgroup>
      <img className='w-auto h-6' src='/logo.png' alt='GivEnergy logo' />
      <p className='mt-4 font-medium text-primary-header'>
        {tcomponent("heading")}
      </p>
      <p>{subheader}</p>
    </hgroup>
  )
}
