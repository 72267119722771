import { forwardRef, useState } from 'react'
import { Eye, EyeOff } from 'lucide-react'

import { cn } from '../utils/class-names.js'

const PasswordInput = forwardRef(
  function PasswordInput ({ label, className, ...rest }, ref) {
    const [type, setType] = useState('password')
    const Icon = type === 'password' ? Eye : EyeOff

    return (
      <label className={cn('block w-64 space-y-1', className)}>
        {label && (
          <span className='block text-sm font-medium capitalize'>
            {label}
          </span>
        )}
        <div className='relative'>
          <input
            {...rest}
            ref={ref}
            type={type}
            className='w-full py-2 pl-3 pr-12 text-sm border-transparent rounded-md shadow ring-1 ring-primary/50 text-primary placeholder:text-primary-muted disabled:bg-primary-100 disabled:cursor-not-allowed'
          />
          <button
            className='absolute -translate-y-1/2 right-3 top-1/2'
            type='button'
            onClick={() => setType(old => old !== 'password' ? 'password' : 'text')}
          >
            <Icon className='text-primary-muted' />
          </button>
        </div>
      </label>
    )
  }
)

export default PasswordInput
