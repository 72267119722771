import { useEffect } from "react";
import { Check } from "lucide-react";

import { useTranslation } from "../hooks/use-translation.js";
import { useAuth } from "../hooks/use-auth.js";

import EmptyState from "../components/empty-state.jsx";

export default function Page() {
  const { tpage } = useTranslation({ page: "completed" });
  const [, { logout }] = useAuth()

  useEffect(() => {
    const fn = () => logout()

    window.addEventListener('beforeunload', fn)

    return () => {
      window.removeEventListener('beforeunload', fn)
    }
  }, [logout]);

  return (
    <EmptyState>
      <div className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-full">
        <Check />
      </div>
      <p className="font-medium text-primary-header">{tpage("header")}</p>
      <p>{tpage("subheader")}</p>
    </EmptyState>
  );
}
