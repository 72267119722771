import { useEffect, useContext, useCallback } from 'react'

import { AuthContext } from '../providers/auth-provider.jsx'

export function useAuth () {
  const [user, set] = useContext(AuthContext)

  const login = useCallback((token, user) => {
    set(user)
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('user', JSON.stringify(user))
  }, [set])

  const logout = useCallback(() => {
    set(null)
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
  }, [set])

  useEffect(() => {
    console.debug('Onboarding user changed %O', user)
  }, [user])

  return [user, { login, logout }]
}
